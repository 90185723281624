import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Text, Box, Section, Icon, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu } from "@quarkly/components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aboutme"} />
		<Helmet>
			<title>
				一相科技官网-和流数字化-代决策
			</title>
			<meta name={"description"} content={"和流 heliu 数字化 和流数字化 一相科技官网 代决策"} />
			<meta property={"og:title"} content={"一相科技官网-和流数字化-代决策"} />
			<meta property={"og:description"} content={"让知道更自然"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%93%9D%E8%89%B2%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.jpeg?v=2023-02-15T09:39:51.187Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
		</Helmet>
		<Section
			height="auto"
			min-height="5vh"
			quarkly-title="Header"
			background="--color-dark"
			color="rgba(0, 0, 0, 0.47)"
			padding="12px 0 12px 0"
			flex-direction="column"
		>
			<Override
				slot="SectionContent"
				align-self="center"
				flex="1 1 0%"
				margin="0px 2% 0px 2%"
				sm-flex="1 1 0%"
				sm-margin="0px 2% 0px 2%"
				width="100%"
			/>
			<Box
				display="flex"
				padding="12px 32px 12px 32px"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex="0 1 auto"
					align-self="center"
					order="-1"
					flex-direction="row"
					position="static"
					sm-min-height="max-content"
				>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13:53:25.176Z"
						display="flex"
						object-fit="contain"
						width="30px"
						height="30px"
						align-self="center"
						align-items="center"
						justify-content="center"
						sm-align-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						margin="0"
						md-margin="0px 0 20px 0"
						text-align="left"
						font="--lead"
						border-color="#fbfbfb"
						color="#ffffff"
						align-self="center"
						sm-align-self="center"
						sm-margin="0px 0 0px 0"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Link href="/index" color="--light" text-decoration-line="initial" target="_self">
								和流®数字化
							</Link>
						</Strong>
					</Text>
				</Box>
				<Menu
					display="flex"
					font="--base"
					font-weight="700"
					md-flex-direction="column"
					md-align-items="center"
					order="1"
					justify-content="center"
				>
					<Override
						slot="link"
						text-decoration="none"
						color="--light"
						padding="6px 12px"
						border-color="--color-light"
					>
						首页
					</Override>
					<Override slot="link-aboutme" href="/aboutme" color="--light">
						关于我们
					</Override>
					<Override slot="item" color="--light" border-color="--color-light" />
				</Menu>
			</Box>
		</Section>
		<Section
			background="linear-gradient(180deg,--color-dark 0%,rgba(0,0,0,0) 31%,transparent 98.6%) 0 0 no-repeat fixed,--color-darkL2 url(https://images.unsplash.com/photo-1676313683415-5bcd3771a56c?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			quarkly-title="Hero2"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							和流数字化
						</Text>
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							让知道更自然
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					向下了解
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline1" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					关于和流
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
					和流®有多年的信息系统研发经验，并与多个低代码平台合作，共同推动和帮助企业
					<Strong>
						以低成本、高效率的方式快速实现数字化转型。
					</Strong>
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="start"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				align-self="auto"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="flex-start"
					height="100%"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="10px 30px 0px 30px"
						align-items="flex-start"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							愿景
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--lead">
							做全球真实利益众生最多的公司
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="flex-start"
					position="relative"
					height="100%"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="10px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							使命
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--lead">
							让知道更自然
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					height="100%"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="10px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
						width="100%"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							价值观
						</Text>
						<List
							margin="0px 0px 0px 0px"
							padding="0px 0px 0px 20px"
							list-style-type="disc"
							as="ul"
							border-color="#191e22"
							color="#191e22"
							font="--lead"
						>
							<Text margin="0px 0px 0px 0px">
								我愿你好
							</Text>
							<Text margin="0px 0px 0px 0px">
								不愿你苦
							</Text>
							<Text margin="0px 0px 0px 0px">
								乐你所喜
							</Text>
							<Text margin="0px 0px 0px 0px">
								爱你如我
							</Text>
						</List>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="5% 0 5% 0" quarkly-title="Contacts">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 10% 0px 10%"
				lg-padding="0px 0px 0px 0px"
				flex="0 1 auto"
			>
				<Text
					margin="30px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="--headline2"
				>
					联系我们
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					font="--lead"
					color="--light"
					text-align="center"
					display="none"
				>
					Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at. Vestibulum nec erat ut mi sollicitudin porttitor id sit amet risus. Nam tempus vel odio vitae aliquam.
				</Text>
				<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
					<Box padding="10px">
						<Image
							src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07:59:18.540Z"
							width="120px"
							max-width="100%"
							srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="0px 0px 0px 0px" color="--light" text-align="center" font="--base">
							微信公众号
						</Text>
					</Box>
				</Box>
				<Link
					href="mailto:blank?heliu@heliu.io"
					text-align="center"
					color="--light"
					font="--lead"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					heliu@heliu.io
				</Link>
				<Text margin="0px 0px 24px 0px" font="--lead" color="--light" text-align="center">
					中国广东省中山市石岐区龙井南路3号222
				</Text>
			</Box>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0" quarkly-title="Footer">
			<Menu
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
				display="flex"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link-index">
					首页
				</Override>
				<Override slot="link-aboutme">
					关于我们
				</Override>
			</Menu>
			<Link text-decoration-line="none" variant="--base" color="--grey" hover-color="--primary">
				© 2023 hlszh.com
			</Link>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"628dd8b721e43d0020958dcd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"6576abcd90c79f2113783a04"}>
				{" window.onload = function() {\n      document.title = '一相科技官网-和流数字化-代决策';\n}"}
			</script>
			<script crossorigin={""} place={"endOfBody"} rawKey={"6576b3b05bc762cc138e174f"}>
				{" window.onload = function() {\n      document.title = '一相科技官网-和流数字化-代决策';\n}"}
			</script>
		</RawHtml>
	</Theme>;
});